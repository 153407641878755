import styled from '@emotion/styled';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React, { useRef } from 'react';
import Layout from '../components/Layout';
import { graphql } from "gatsby";
import SEO from "../components/SEO";

function AboutMe({data}) {
    const {body, frontmatter} = data.mdx;
    const selectionRef = useRef(null);

    return (
        <Layout>
            <SEO
                title={ frontmatter.title }
                description={ frontmatter.title }
            />
            <TitleWrapper>
                <Title>{ frontmatter.title }</Title>
            </TitleWrapper>
            <div ref={ selectionRef }>
                <MDXRenderer>{ body }</MDXRenderer>
            </div>
        </Layout>
    );
}

export const query = graphql`
  query AboutBySlug($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      timeToRead
      frontmatter {
        date(formatString: "MMMM Do, YYYY")
        title
        featuredImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
          }
        }
      } }
    }
  }
`;


const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 5rem;
`;

const Title = styled.h1`
  font-size: 2.25rem;
  line-height: 2.5rem;
  font-family: var(--font-serif);
  @media (min-width: 768px) {
    font-size: 3rem;
    line-height: 3.5rem;
  }
`;

const Subtitle = styled.div`
  padding-top: 1.25rem;
  margin-bottom: 8rem;
  color: var(--color-muted);
`;


export default AboutMe;
